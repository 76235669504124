import axios from "axios";

const state = () => ({
    notifications: []
})

const mutations = {
    setNotifications (state, data) {
        state.notifications = data
    }
}

const actions = {
    async getNotifications ({ commit }) {
        const res = await axios.get('/notifications')
        commit('setNotifications', res.data.data)
    },
    async deleteNotification ({ dispatch }, id) {
        await axios.delete(`/notifications/${id}`)
        dispatch('getNotifications')
    },
    async checkNotification (ctx, { id, entity }) {
        await axios.delete('/notifications/destroy_by_entity', {
            params: {
                id,
                entity
            }
        })
    }
}

export default {
    state,
    mutations,
    actions
}
