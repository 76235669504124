import axios from "axios";

const state = () => ({
    quantity: {
        orders: 0,
        provider_orders: 0,
        consignments: 0,
        consignment_registers: 0,
        payment_registers: 0,
        price_negotiations: 0,
        request_additions: 0,
        organization_notifications: 0
    }
})

const mutations = {
    setQuantity (state, data) {
        state.quantity = data ? data : {}
    }
}

const actions = {
    async getQuantity ({ commit }) {
        const res = await axios.get('/notifications/count')
        commit('setQuantity', res.data)
    }
}

export default {
    state,
    mutations,
    actions
}
