export default {
  methods: {
    setBreadcrumbs() {
      if (this.breadcrumbs) {
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
      }
    },
  },
  watch: {
    breadcrumbs: {
      handler() {
        this.setBreadcrumbs();
      },
      deep: true,
    },
  },
  created() {
    this.setBreadcrumbs();
  },
};
