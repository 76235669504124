import axios from "axios"

const actions = {
    async getNomenclatureSearch(ctx, query) {
        const res = await axios.get('/references/nomenclature', {
            params: {
                pagination: 'off',
                name: query
            }
        })
        return res.data.data
    },
    async getMnemocodeSearch(ctx, query) {
        const res = await axios.get('/references/nomenclature', {
            params: {
                pagination: 'off',
                mnemocode: query
            }
        })
        return res.data.data
    },
    async getCurrentNomenclature(ctx, id) {
        const res = await axios.get(`/references/nomenclature/${id}`)
        return res.data.data
    }
}

export default {
    actions
}
