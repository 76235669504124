const state = {
  list: []
}

const mutations = {
  setBreadcrumbs (state, data) {
    state.list = data
  }
}

export default {
    state,
    mutations
}
