import axios from 'axios';

const state = () => ({
  user: {
    name: '',
    surname: '',
    middlename: '',
    contr_agent: '',
    position: '',
    phone: '',
    email: '',
    roles: [],
  },
});

const getters = {
  isProvider(state) {
    return state.user.roles.find((i) => i === 'provider');
  },
  isContractor(state) {
    return state.user.roles.find((i) => i === 'contractor');
  },
};

const mutations = {
  setUser(state, data) {
    for (let key in data) {
      state.user[key] = data[key];
    }
  },
};

const actions = {
  async getUser({ commit }) {
    // const calcUrl =
    //   process.env.NODE_ENV === 'production' ? '/webapi/me' : '/me';
    const res = await axios.get('/me');
    commit('setUser', res.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
