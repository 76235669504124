<template>
    <div id="app">
        <div
            v-if="user.roles.length"
            class="wrapper"
        >
            <template v-if="componentLoaded">
                <component :is="layout">

                    <router-view/>

                </component>
            </template>
        </div>
        <div
            v-else
            class="d-flex align-items-center justify-content-center vh-100"
        >
            <div class="text-center">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    variant="primary"
                />
                <span class="h5 d-block mt-3">Загрузка приложения...</span>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default"
import AuthLayout from "@/layouts/Auth"

export default {
    components: {
        DefaultLayout,
        AuthLayout
    },
    computed: {
        user() {
            return this.$store.state.account.user
        },
        layout() {
            return (this.$route.meta.layout || 'default') + '-layout'
        }
    },
    data: () => ({
        componentLoaded: true
    }),
    watch: {
        $route() {
            this.componentLoaded = true
        }
    },
    methods: {
        getGlobalData() {
            return Promise.all([
                this.$store.dispatch('getReferenceBooks'),
                this.$store.dispatch('getGlossary')
            ])
        }
    },
    async created() {
        if (this.$keycloak.authenticated) {
            await this.getGlobalData()
        }
    }
}
</script>
