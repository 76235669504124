<template>
    <footer class="footer py-2 bg-light mt-auto">
        <div class="container">

            <div class="d-flex align-items-center justify-content-between">

                <div class="mr-3 text-muted">© 2021, АО «Сетевая компания»</div>

                <a
                    href="#"
                    class="text-primary"
                    style="text-decoration: underline">Техническая поддержка</a>

            </div>

        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
