import axios from "axios";
import router from "@/router";
import Vue from "vue";

const state = () => ({
  providerOrders: {
    data: [],
  },
  currentProviderOrder: {
    number: "",
    actual_positions: {
      data: [],
    },
    base_positions: {
      data: [],
    },
    order_corrections: {
      data: [],
    },
    requirement_corrections: {
      data: [],
    },
  },
});

const mutations = {
  setProviderOrders(state, list) {
    state.providerOrders = list;
  },
  setCurrentProviderOrder(state, data) {
    for (let key in data) {
      if (
        key !== "actual_positions" &&
        key !== "base_positions" &&
        key !== "order_corrections" &&
        key !== "requirement_corrections"
      ) {
        Vue.set(state.currentProviderOrder, key, data[key]);
      }
    }
  },
  setActualPositions(state, data) {
    Vue.set(state.currentProviderOrder, "actual_positions", data);
  },
  setBasePositions(state, data) {
    Vue.set(state.currentProviderOrder, "base_positions", data);
  },
  setOrderCorrections(state, data) {
    Vue.set(state.currentProviderOrder, "order_corrections", data);
  },
  setRequirementCorrections(state, data) {
    Vue.set(state.currentProviderOrder, "requirement_corrections", data);
  },
};

const actions = {
  async getProviderOrders({ commit }, filters = {}) {
    const res = await axios.get("/provider-orders", {
      params: filters,
    });
    commit("setProviderOrders", res.data);
  },
  async getCurrentProviderOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/provider-orders/${id}`)
        .then((res) => {
          commit("setCurrentProviderOrder", res.data.data);
          resolve();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            router.push("/provider/purchase-orders");
          }
          reject();
        });
    });
  },
  async getActualPositions({ commit }, { id, filters = {} }) {
    const res = await axios.get(`/provider-orders/${id}/actual_positions`, {
      params: filters,
    });
    commit("setActualPositions", res.data.data);
  },
  async getBasePositions({ commit }, { id, filters = {} }) {
    const res = await axios.get(`/provider-orders/${id}/base_positions`, {
      params: filters,
    });
    commit("setBasePositions", res.data.data);
  },
  async getRequirementCorrections({ commit }, { id, filters = {} }) {
    const res = await axios.get(
      `/provider-orders/${id}/requirement-corrections`,
      {
        params: filters,
      }
    );
    commit("setRequirementCorrections", res.data.data);
  },
  async getOrderCorrections({ commit }, { id, filters = {} }) {
    const res = await axios.get(`/provider-orders/${id}/order-corrections`, {
      params: filters,
    });
    commit("setOrderCorrections", res.data.data);
  },
};

export default {
  state,
  mutations,
  actions,
};
