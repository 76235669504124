import axios from 'axios'

const actions = {
    async downloadFile(ctx, { from, id }) {
        return new Promise(((resolve, reject) => {
            axios.get(`/${from}/${id}/file_download`, {
                responseType: 'blob'
            })
                .then(resolve)
                .catch(reject)
        }))
    }
}

export default {
    actions
}
