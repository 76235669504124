<template>
    <header class="header">

        <HeaderTop />

        <HeaderMenu />

    </header>
</template>

<script>

    import HeaderTop from '@/components/Header/Top'
    import HeaderMenu from '@/components/Header/Menu'

    export default {
        name: "Header",
        components: {
            HeaderTop,
            HeaderMenu
        }
    }
</script>

<style scoped>

</style>
