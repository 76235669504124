import axios from 'axios';
import Vue from 'vue';

const state = () => ({
  orders: null,
  currentOrder: {
    number: '',
    order_date: '',
    customer: {},
    provider: {
      contact: {},
    },
    contractor: {
      contact: {},
    },
    positions: {
      data: [],
    },
  },
});

const actions = {
  async getOrders({ commit }, filters) {
    let params = {};
    if (filters) {
      params = filters;
    }
    const res = await axios.get('/orders', {
      params,
    });
    commit('setOrders', res.data.data);
  },
  async getOrder({ commit }, id) {
    const res = await axios.get(`/orders/${id}`);
    commit('setOrder', res.data.data);
    commit('setOrderPositions', res.data.data.positions);
  },
  async getOrderPositions({ commit }, { id, filters = {} }) {
    const res = await axios.get(`/orders/${id}/positions`, {
      params: filters,
    });
    commit('setOrderPositions', res.data);
  },
  deleteOrder(ctx, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/orders/${id}`).then(resolve).catch(reject);
    });
  },
  approveOrder(ctx, { id, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/orders/${id}/approve`, {
          comment,
        })
        .then(resolve)
        .catch(reject);
    });
  },
  rejectOrder(ctx, { id, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/orders/${id}/reject`, {
          comment,
        })
        .then(resolve)
        .catch(reject);
    });
  },
  mtrReject(ctx, { id, rejected_position_ids, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/orders/${id}/reject_positions`, {
          rejected_position_ids,
          comment,
        })
        .then(resolve)
        .catch(reject);
    });
  },
  closeOrder(ctx, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/orders/${id}/close`).then(resolve).catch(reject);
    });
  },
  confirmCloseOrder(ctx, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/orders/${id}/approve_closing`).then(resolve).catch(reject);
    });
  },
};

const mutations = {
  setOrders(state, data) {
    state.orders = data;
  },
  setOrder(state, data) {
    for (let key in data) {
      if (key !== 'positions') {
        Vue.set(state.currentOrder, key, data[key]);
      }
    }
  },
  clearOrder(state) {
    state.currentOrder = null;
  },
  setOrderPositions(state, data) {
    state.currentOrder.positions = data;
  },
};

export default {
  state,
  actions,
  mutations,
};
