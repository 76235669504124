export default {
  methods: {
    async onExport( entity, entity_id, type = 1 ) {
      entity
      entity_id
      type
      const baseURL = (
          ( process.env.NODE_ENV === 'production' )
              ? process.env.VUE_APP_API_URL
              : process.env.VUE_APP_API_DEV_URL
      )
      const token = this.$keycloak.token

      const params = (
        new URLSearchParams( {
          entity,
          entity_id,
          type
        } )
      )
      const url = baseURL + 'report/download?' + params
      const options = {
          headers: {
              Authorization: 'Bearer ' + token
          }
      }
      const response = await fetch( url, options )
      const blob = await response.blob()

      const target = window.URL.createObjectURL( blob )
      window.open( target )
    }
  }
}