import axios from "axios"

const state = () => ({
    nsiList: [],
    currentNsi: {
        number: '',
        date: '',
        provider_contract: {},
        organization: {},
        nomenclature: null
    }
})

const mutations = {
    setNsiList (state, data) {
        state.nsiList = data
    },
    setCurrentNsi (state, data) {
        state.currentNsi = data
    }
}

const actions = {
    async getNsiList ({ commit }, filters) {
        let params = {}
        if (filters) {
            params = filters
        }
        const res = await axios.get('/request-addition/nomenclature', {
            params
        })
        commit('setNsiList', res.data)
    },
    async getCurrentNsi ({ commit }, id) {
        const res = await axios.get(`/request-addition/nomenclature/${id}`)
        commit('setCurrentNsi', res.data.data)
    },
    async getNsiOrganizations (ctx, contract_id) {
        const res = await axios.get('/request-addition/nomenclature/organizations', {
            params: {
                contract_id
            }
        })
        return res.data
    },
    deleteNsi(ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.delete(`/request-addition/nomenclature/${id}`).then(resolve).catch(reject)
        }))
    },
    createNsi(ctx, data) {
        const formData = new FormData()

        for (let key in data) {
            const value = data[key]

            if (value !== null) {
                formData.append(key, value)
            }
        }

        return new Promise((resolve, reject) => {
            axios.post('/request-addition/nomenclature', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    updateNsi(ctx, {id, data}) {
        const formData = new FormData()

        for (let key in data) {
            const value = data[key]

            if (value !== null) {
                formData.append(key, value)
            }
        }

        return new Promise((resolve, reject) => {
            axios.post(`/request-addition/nomenclature/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    state,
    mutations,
    actions
}
