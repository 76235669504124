<template>
    <div class="header-logo">
        <router-link to="/" class="logo">
            <img
                src="@/assets/img/logo.png"
                alt=""
            >
        </router-link>
    </div>
</template>

<script>
export default {
    name: "Logo"
}
</script>

<style
    lang="sass"
    scoped
>

</style>
