import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue')
    },
    {
        path: '/purchase-orders',
        name: 'Purchase Orders',
        component: () => import('@/views/PurchaseOrders.vue')
    },
    {
        path: '/purchase-orders/:id',
        name: 'Purchase Order',
        component: () => import('@/views/PurchaseOrder.vue')
    },
    {
        path: '/purchase-orders/:id/edit',
        name: 'Purchase Order Edit',
        component: () => import('@/views/PurchaseOrderEdit.vue'),
        meta: {
            forContractor: true
        }
    },
    {
        path: '/create-order/',
        name: 'Create Order',
        component: () => import('@/views/CreateOrder.vue')
    },
    {
        path: '/provider',
        name: 'Provider',
        redirect: '/provider/purchase-orders'
    },
    {
        path: '/provider/purchase-orders',
        name: 'Provider Orders',
        component: () => import('@/views/ProviderOrders.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/provider/purchase-orders/:id',
        name: 'Provider Order',
        component: () => import('@/views/ProviderOrder.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/provider/purchase-orders/:orderId/adjustments/:id',
        name: 'Adjustment',
        component: () => import('@/views/Adjustment.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/provider/purchase-orders/:orderId/order-corrections/:id',
        name: 'Corrections',
        component: () => import('@/views/OrderCorrection.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/registries',
        name: 'Registries',
        component: () => import('@/views/Registries.vue')
    },
    {
        path: '/registries/:id',
        name: 'Registries Order',
        component: () => import('@/views/RegistriesItem.vue')
    },
    {
        path: '/registries/:id/edit',
        name: 'Registries Order Edit',
        component: () => import('@/views/RegistriesItemEdit.vue')
    },
    {
        path: '/create-registry',
        name: 'Registries Order',
        component: () => import('@/views/CreateRegistry.vue'),
        meta: {
            forContractor: true
        }
    },
    {
        path: '/consignment-notes',
        name: 'Consignment Notes List',
        component: () => import('@/views/ConsignmentNotesList.vue')
    },
    {
        path: '/consignment-notes/:id',
        name: 'Consignment Note',
        component: () => import('@/views/ConsignmentNote.vue')
    },
    {
        path: '/consignment-notes/:id/edit',
        name: 'Consignment Note Edit',
        component: () => import('@/views/ConsignmentNoteEdit.vue')
    },
    {
        path: '/create-consignment-note',
        name: 'Create Consignment Note',
        component: () => import('@/views/CreateConsignmentNote.vue')
    },
    {
        path: '/consignment-notes-registries',
        name: 'Consignmen Notes Registries',
        component: () => import('@/views/ConsignmentNotesRegistries.vue')
    },
    {
        path: '/create-consignment-notes-registry',
        name: 'Create Consignment Notes Registry',
        component: () => import('@/views/CreateConsignmentNotesRegistry.vue')
    },
    {
        path: '/consignment-notes-registries/:id',
        name: 'Consignment Notes Registry',
        component: () => import('@/views/ConsignmentNotesRegistry.vue')
    },
    {
        path: '/consignment-notes-registries/:id/edit',
        name: 'Consignment Note Registry Edit',
        component: () => import('@/views/ConsignmentNotesRegistryEdit.vue')
    },
    {
        path: '/provider-notifications',
        name: 'Provider Notifications',
        component: () => import('@/views/ProviderNotifications.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/provider-notifications/:id',
        name: 'Provider Notification',
        component: () => import('@/views/ProviderNotification.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/create-provider-notification/',
        name: 'Create Provider Notification',
        component: () => import('@/views/CreateProviderNotification.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/provider-notifications/:id/edit',
        name: 'Provider Notification Edit',
        component: () => import('@/views/ProviderNotificationEdit.vue'),
        meta: {
            forProvider: true
        }
    },
    {
        path: '/price-negotiations',
        name: 'Price Negotiations',
        component: () => import('@/views/PriceNegotiations.vue')
    },
    {
        path: '/price-negotiations/:id',
        name: 'Price Negotiation',
        component: () => import('@/views/PriceNegotiation.vue')
    },
    {
        path: '/create-price-negotiation',
        name: 'Create Price Negotiation',
        component: () => import('@/views/CreatePriceNegotiation.vue')
    },
    {
        path: '/price-negotiations/:id/edit',
        name: 'Price Negotiation Edit',
        component: () => import('@/views/PriceNegotiationEdit.vue')
    },
    {
        path: '/price-negotiations-report',
        name: 'Price Negotiations Report',
        component: () => import('@/views/PriceNegotiationsReport.vue')
    },
    {
        path: '/nsi-list',
        name: 'Nsi List',
        component: () => import('@/views/NsiList.vue')
    },
    {
        path: '/nsi-list/:id',
        name: 'Nsi Item',
        component: () => import('@/views/NsiItem.vue')
    },
    {
        path: '/nsi-list/:id/edit',
        name: 'Nsi Item Edit',
        component: () => import('@/views/NsiItemEdit.vue')
    },
    {
        path: '/create-nsi',
        name: 'Create Nsi',
        component: () => import('@/views/CreateNsi.vue')
    },
    {
        path: '/create-nsi',
        name: 'Create Nsi',
        component: () => import('@/views/CreateNsi.vue')
    },
    {
        path: '/reference-books',
        name: 'Reference Books',
        component: () => import('@/views/ReferenceBooks.vue')
    },
    {
        path: '/reference-books/nomenclature/:id',
        name: 'Reference Book Nomenclature',
        component: () => import('@/views/ReferenceBookNomenclature.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/NotFound.vue')
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base:'/',
    root:'/'
})

function checkRole(to, from, next) {
    const isProvider = store.getters.isProvider
    const isContractor = store.getters.isContractor

    if (to.meta.forContractor) {
        if (isContractor) {
            next()
        } else {
            next(from.fullPath)
        }
        return false
    }
    if (to.meta.forProvider) {
        if (isProvider) {
            next()
        } else {
            next(from.fullPath)
        }
        return false
    }
    next()
}

router.beforeEach((to, from, next) => {
    const authenticated = Vue.$keycloak.authenticated

    if (!authenticated && to.path !== '/login') {
        next('/login')
    } else if (authenticated) {

        if (to.path === '/login') {
            next('/')
        }

        Vue.$keycloak.updateToken(-1)
            .then(response => {
                if (response) {
                    checkRole(to, from, next)
                } else {
                    next('/login')
                }
            })
    } else {
        checkRole(to, from, next)
    }
})

export default router
