<template>
    <div class="header-menu bg-light py-1">
        <div class="container">
            <nav class="header-menu d-flex align-items-center">

                <ul>
                    <li
                        v-for="(link, index) in links"
                        :key="index">
                        <router-link :to="link.url">{{ link.title }} <b-icon v-if="link.childrens" icon="chevron-down"></b-icon></router-link>
                        <ul v-if="link.childrens">
                            <template v-for="(subLink, subIndex) in link.childrens">
                                <li
                                    v-if="subLink.for === 'provider' ? isProvider : true"
                                    :key="`${index}-${subIndex}`"
                                >
                                    <router-link :to="subLink.url">{{ subLink.title }} <b-icon v-if="subLink.childrens" icon="chevron-right"></b-icon></router-link>
                                    <ul v-if="subLink.childrens">
                                        <li
                                            v-for="(subSubLink, subSubIndex) in subLink.childrens"
                                            :key="`${index}-${subIndex}-${subSubIndex}`">
                                            <router-link :to="subSubLink.url">{{ subSubLink.title }}</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </li>
                </ul>

            </nav>
        </div>
    </div>
</template>

<script>

import {mapState, mapGetters} from 'vuex'

export default {
    name: "HeaderMenu",
    computed: {
        ...mapState({
            links: state => state.menu.menu
        }),
        ...mapGetters([
            'isProvider'
        ])
    }
}
</script>

<style scoped>

</style>
